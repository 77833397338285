import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Search from "../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import Loader from "../../Helper/Loader";

const Enquiry = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Enquiry",
      href: "#",
    },
  ];
  let mapSource = {
      affiliated: "Affliated",
      online: "Online",
      admin: "Admin",
    },
    mapStatus = {
      awaiting_booking: "Booking Awaiting",
      idea_dropped: "Idea Dropped",
      fixed_mandap: "Mandap Fixed",
    };
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const deleteRow = (row) => {
    setLoader(true);
    axios
      .delete(config.service + "/customer/enquiry/list/" + row.id, tokenKey)
      .then((resp) => {
        get(config.service + "/customer/enquiry/list/", tokenKey).then(
          (res) => {
            settableData(res.data);
            setLoader(false);
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        setLoader(false);
      });
  };
  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/enquiryForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/enquiryForm",
        state: {},
      });
  };
  const renderOnSearch = (searchTerm) => {
    setLoader(true);
    get(
      config.service + "/customer/enquiry/list/?&search=" + searchTerm,
      tokenKey
    )
      .then((res) => {
        settableData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Search Failed", toastAttr);
        setLoader(false);
      });
  };
  useEffect(() => {
    get(config.service + "/customer/enquiry/list/", tokenKey)
      .then((res) => {
        settableData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
    setLoader(false);
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: tableData.length,
  });

  const cols = [
    {
      dataField: "enquiry_no",
      text: "Enquiry Number",
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "mandap_name",
      text: "Mandap Name",
      headerStyle: () => {
        return { width: "300px" };
      },
    },
    {
      dataField: "date_from",
      text: "Booking From",
      headerStyle: () => {
        return { width: "180px" };
      },
      formatter: (cell, row) => {
        let fromDate = row.date_from
          ? row.date_from.replace("T", " ").replace("Z", "")
          : "";
        return <div>{fromDate.slice(0, 10)}</div>;
      },
    },
    {
      dataField: "date_to",
      text: "Booking To",
      headerStyle: () => {
        return { width: "180px" };
      },
      formatter: (cell, row) => {
        let toDate = row.date_to
          ? row.date_to.replace("T", " ").replace("Z", "")
          : "";
        return <div>{toDate.slice(0, 10)}</div>;
      },
    },
    {
      dataField: "date_of_booking",
      text: "Date of Enquiry",
      headerStyle: () => {
        return { width: "180px" };
      },
      formatter: (cell, row) => {
        let dateOfBooking = row.created_on
          ? row.created_on.replace("T", " ").replace("Z", "")
          : "";
        return <div>{dateOfBooking.slice(0, 10)}</div>;
      },
    },
    {
      dataField: "enquiry_source",
      text: "Source",
      headerStyle: () => {
        return { width: "120px" };
      },
      formatter: (cell) => {
        return <div>{mapSource[cell] || null}</div>;
      },
    },
    {
      dataField: "enquiry_status",
      text: "Feedback",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell) => {
        return <div>{mapStatus[cell] || null}</div>;
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerStyle: () => {
        return { width: "60px" };
      },
      events: {
        onClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions">
            <React.Fragment>
              <Link
                to={{
                  pathname: "/enquiryForm",
                  state: { id: row.id, editMode: true },
                }}
              >
                <FiEdit2 title="Edit" />
              </Link>
              {/* <HiOutlineTrash title='Delete'  onClick={e => deleteRow(row) } /> */}
            </React.Fragment>
          </div>
        );
      },
    },
  ];
  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/enquiryForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    },
  };
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button variant="primary" onClick={(e) => renderForm()}>
                    Create
                  </Button>
                </Col>
                <Col md={4}>
                  <Search callbackFn={(e) => renderOnSearch(e)} />
                </Col>
              </Row>
              <Loader loading={loading} overlay={overlay}>
                <Row className="vendorList">
                  <BootstrapTable
                    bootstrap4
                    wrapperClasses="table-responsive pad-20"
                    data={tableData}
                    columns={cols}
                    keyField="id"
                    containerStyle={{ width: "100%" }}
                    hover
                    noDataIndication="No data found"
                    rowEvents={rowEvents}
                    pagination={options}
                  />
                </Row>
              </Loader>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};
export default Enquiry;
