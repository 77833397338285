import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import LogIn from "../LogIn/index";
import ResetPassword from "../LogIn/components/ResetPassword";
import Dashboard from "../Components/Dashboard";
import Vendor from "../Components/Vendor";
import VendorForm from "../Components/Vendor/form";
import MandapList from "../Components/Vendor/views/mandapList";
import Booking from "../Components/Vendor/views/bookingList";
import BookingForm from "../Components/Vendor/views/booking";
import User from "../Components/User";
import UserForm from "../Components/User/form";
import Settings from "../Components/Settings";
import QRCode from "../Components/Settings/QRCode";
import QRCodeForm from "../Components/Settings/QRCode/form";
import Target from "../Components/Settings/Target";
import TargetForm from "../Components/Settings/Target/form";
import Masters from "../Components/Masters";
import InquiryPoint from "../Components/InquiryPoint";
import SignUpLeads from "../Components/SignUpLeads";
import RazorPayLogs from "../Components/Masters/RazorPayLogs";
import City from "../Components/Masters/City";
import CityForm from "../Components/Masters/City/form";
import State from "../Components/Masters/State";
import MuhurthaCalandar from "../Components/Masters/MuhurthaCalandar";
import StateForm from "../Components/Masters/State/form";
import Area from "../Components/Masters/Area";
import AreaForm from "../Components/Masters/Area/form";
import MandapType from "../Components/Masters/MandapType";
import MandapTypeForm from "../Components/Masters/MandapType/form";
import VendorCategory from "../Components/Masters/VendorCategory";
import VendorCategoryForm from "../Components/Masters/VendorCategory/form";
import MandapClassification from "../Components/Masters/MandapClassification";
import MandapClassificationForm from "../Components/Masters/MandapClassification/form";
import TypeofConcern from "../Components/Masters/TypeofConcern";
import TypeofConcernForm from "../Components/Masters/TypeofConcern/form";
import ImageCategory from "../Components/Masters/ImageCategory";
import ImageCategoryForm from "../Components/Masters/ImageCategory/form";
import QuotationType from "../Components/Masters/QuotationType";
import QuotationTypeForm from "../Components/Masters/QuotationType/form";
import IdProof from "../Components/Masters/IdProof";
import IdProofForm from "../Components/Masters/IdProof/form";
import Campaign from "../Components/Masters/Campaign";
import CampaignForm from "../Components/Masters/Campaign/form";
import FeedBack from "../Components/Masters/FeedBack";
import FeedBackForm from "../Components/Masters/FeedBack/form";
import MandapAvailabilityForm from "../Components/Masters/MandapAvailability/form";
import FunctionType from "../Components/Masters/FunctionType";
import FunctionTypeForm from "../Components/Masters/FunctionType/form";
import Payment from "../Components/Payment";
import PaymentLogs from "../Components/Payment/Logs";
import PaymentLinks from "../Components/Payment/Links";
import PaymentLogForm from "../Components/Payment/Logs/form";
import PaymentLinkForm from "../Components/Payment/Links/form";
import ViewPayment from "../Components/Payment/ViewPayment";
import ViewPaymentForm from "../Components/Payment/ViewPayment/form";
import PaymentResponse from "../Components/Payment/PaymentResponse";
import PaymentResponseForm from "../Components/Payment/PaymentResponse/form";
import Voucher from "../Components/Voucher";
import VoucherForm from "../Components/Voucher/form";
import Cashback from "../Components/Cashback";
import CashbackForm from "../Components/Cashback/form";
import ClaimBonus from "../Components/ClaimBonus";
import ClaimBonusForm from "../Components/ClaimBonus/form";
import Pricing from "../Components/Pricing";
import PricingForm from "../Components/Pricing/form";
import Seotags from "../Components/Seotags";
import SeotagsForm from "../Components/Seotags/form";
import PriceDetails from "../Components/Masters/Price";
import PriceDetailsForm from "../Components/Masters/Price/form";
import Customers from "../Components/Customer";
import UpdateDates from "../Components/UpdateDates";
import Enquiry from "../Components/Enquiry";
import EnquiryForm from "../Components/Enquiry/form";
import UpdateContent from "../Components/Settings/UpdateContent";
import Reviews from "../Components/Settings/Reviews";
import ReviewsForm from "../Components/Settings/Reviews/form";
import MandapTypeSeoForm from "../Components/Masters/MandapTypeSeo/form";
import mandapTypeSeo from "../Components/Masters/MandapTypeSeo";
import Report from "../Components/Dashboard/Report";
import Faq from "../Components/Masters/Faq";
import FaqForm from "../Components/Masters/Faq/form";
import Notification from "../Components/Notifications/notification";
import NotifyForm from "../Components/Notifications/notifyform";
import PaymentStatus from "../Components/paymentgateway/index";
import EnquiryBook from "../Components/EnquiryBooking/index";
import Users from "../Components/Masters/Users";
import UserLogs from "../Components/Masters/UserLogs";
import BlogPost from "../Components/Masters/Blog";
import BlogPostForm from "../Components/Masters/Blog/form";
import Post from "../Components/Masters/Post";
import PostForm from "../Components/Masters/Post/form";
import Discounts from "../Components/Masters/Discounts";
import VedorMandapPending from "../Components/Vendor/pending";

const Pages = () => (
  <Switch>
    <Route path="/dashboard" component={Dashboard} />
    {/* <Route path="/profile" component={Profile} /> */}
    <Route path="/vendor" component={Vendor} />
    <Route path="/vendorForm" component={VendorForm} />
    <Route path="/pendingList" component={VedorMandapPending} />
    <Route path="/mandapList" component={MandapList} />
    <Route path="/booking" component={Booking} />
    <Route path="/bookingForm/:bookingId" component={BookingForm} />
    <Route path="/bookingForm" component={BookingForm} />
    <Route path="/user" component={User} />
    <Route path="/userForm" component={UserForm} />
    <Route path="/settings/qrcode" component={QRCode} />
    <Route path="/settings/qrcodeForm" component={QRCodeForm} />
    <Route path="/settings/target" component={Target} />
    <Route path="/settings/targetForm" component={TargetForm} />
    <Route path="/settings/updateDates" component={UpdateDates} />
    <Route path="/settings/updateContent" component={UpdateContent} />
    <Route path="/settings/reviews" component={Reviews} />
    <Route path="/settings/reviewsForm" component={ReviewsForm} />
    <Route path="/settings" component={Settings} />
    <Route path="/masters/city" component={City} />
    <Route path="/masters/cityForm" component={CityForm} />
    <Route path="/masters/state" component={State} />
    <Route path="/masters/MuhurthaCalandar" component={MuhurthaCalandar} />
    <Route path="/masters/stateForm" component={StateForm} />
    <Route path="/masters/area" component={Area} />
    <Route path="/masters/areaForm" component={AreaForm} />
    <Route path="/masters/mandaptype" component={MandapType} />
    <Route path="/masters/mandaptypeForm" component={MandapTypeForm} />
    <Route path="/masters/vendorcategory" component={VendorCategory} />
    <Route path="/masters/vendorcategoryForm" component={VendorCategoryForm} />
    <Route
      path="/masters/mandapclassification"
      component={MandapClassification}
    />
    <Route
      path="/masters/mandapclassificationForm"
      component={MandapClassificationForm}
    />
    <Route path="/masters/typeofconcern" component={TypeofConcern} />
    <Route path="/masters/typeofconcernForm" component={TypeofConcernForm} />
    <Route path="/masters/imagecategory" component={ImageCategory} />
    <Route path="/masters/imagecategoryForm" component={ImageCategoryForm} />
    <Route path="/masters/quotationtype" component={QuotationType} />
    <Route path="/masters/quotationtypeForm" component={QuotationTypeForm} />
    <Route path="/masters/idproof" component={IdProof} />
    <Route path="/masters/idproofForm" component={IdProofForm} />
    <Route path="/masters/campaign" component={Campaign} />
    <Route path="/masters/campaignForm" component={CampaignForm} />
    <Route path="/masters/feedback" component={FeedBack} />
    <Route path="/masters/feedbackForm" component={FeedBackForm} />
    <Route
      path="/masters/mandapavailabilityForm"
      component={MandapAvailabilityForm}
    />
    <Route path="/masters/razorpaylogs" component={RazorPayLogs} />
    <Route path="/masters/functiontype" component={FunctionType} />
    <Route path="/masters/functiontypeForm" component={FunctionTypeForm} />
    <Route path="/masters/priceDetails" component={PriceDetails} />
    <Route path="/masters/priceDetailsForm" component={PriceDetailsForm} />
    <Route path="/masters/mandapTypeSeo" component={mandapTypeSeo} />
    <Route path="/masters/mandapTypeSeoForm" component={MandapTypeSeoForm} />
    <Route path="/masters/faq" component={Faq} />
    <Route path="/masters/faqForm" component={FaqForm} />

    <Route path="/masters/blogPost" component={BlogPost} />
    <Route path="/masters/blogPostForm" component={BlogPostForm} />
    <Route path="/masters/post" component={Post} />
    <Route path="/masters/postForm" component={PostForm} />
    <Route path="/masters/users" component={Users} />
    <Route path="/masters/userlogs" component={UserLogs} />
    <Route path="/masters/discounts" component={Discounts} />

    <Route path="/masters" component={Masters} />
    <Route path="/inquirypoint" component={InquiryPoint} />
    <Route path="/signuppoint" component={SignUpLeads} />
    <Route path="/payment/logs" component={PaymentLogs} />
    <Route path="/payment/logForm" component={PaymentLogForm} />
    <Route path="/payment/links" component={PaymentLinks} />
    <Route path="/payment/linkForm" component={PaymentLinkForm} />
    <Route path="/payment" component={Payment} />
    <Route path="/viewPayment" component={ViewPayment} />
    <Route path="/viewPaymentForm" component={ViewPaymentForm} />
    <Route path="/paymentResponse" component={PaymentResponse} />
    <Route path="/paymentResponseForm" component={PaymentResponseForm} />
    <Route path="/voucher" component={Voucher} />
    <Route path="/voucherForm" component={VoucherForm} />
    <Route path="/cashback" component={Cashback} />
    <Route path="/cashbackForm" component={CashbackForm} />
    <Route path="/claimBonus" component={ClaimBonus} />
    <Route path="/claimBonusForm" component={ClaimBonusForm} />
    <Route path="/pricing" component={Pricing} />
    <Route path="/pricingForm" component={PricingForm} />
    <Route path="/seotags" component={Seotags} />
    <Route path="/seotagsForm" component={SeotagsForm} />
    <Route path="/customers" component={Customers} />
    <Route path="/enquiry" component={Enquiry} />
    <Route path="/enquiryForm" component={EnquiryForm} />
    <Route path="/report" component={Report} />
    <Route path="/notifications" component={Notification} />
    <Route path="/NotifyForm" component={NotifyForm} />
    <Route path="/paymentstatus" component={PaymentStatus} />
    <Route path="/enqbook" component={EnquiryBook} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/resetPassword/:token" component={ResetPassword} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
