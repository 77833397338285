import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FaCriticalRole } from "react-icons/fa";
import { HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import Search from "../../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../../Helper/Breadcrumbs";

const PriceDetails = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.location.mandapclasiification;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Price Details",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const deleteRow = (row) => {
    axios
      .delete(config.service + "/masters/pricelist/delete/" + row.id, tokenKey)
      .then((resp) => {
        get(config.service + "/masters/pricelist/", tokenKey).then((res) => {
          settableData(res.data);
        });
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  };

  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/masters/priceDetailsForm",
        state: {
          id: row.id,

          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/masters/priceDetailsForm",
        state: {},
      });
  };
  const renderOnSearch = (searchTerm) => {
    get(config.service + "/masters/pricelist/?&search=" + searchTerm, tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Search Failed", toastAttr);
      });
  };
  useEffect(() => {
    get(config.service + "/masters/pricelist/", tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    totalSize: tableData.length,
  });

  const cols = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: () => {
        return { width: "400px" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: () => {
        return { width: "400px" };
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerStyle: () => {
        return { width: "80px" };
      },
      events: {
        onClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions">
            <React.Fragment>
              <Link
                to={{
                  pathname: "/masters/priceDetailsForm",
                  state: { id: row.id },
                }}
              >
                <FiEdit2 title="Edit" />
              </Link>
              <HiOutlineTrash title="Delete" onClick={(e) => deleteRow(row)} />
            </React.Fragment>
          </div>
        );
      },
    },
  ];
  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/masters/priceDetailsForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    },
  };
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button variant="primary" onClick={(e) => renderForm()}>
                    Create
                  </Button>
                </Col>
                <Col md={4}>
                  <Search callbackFn={(e) => renderOnSearch(e)} />
                </Col>
              </Row>

              <Row className="vendorList">
                <BootstrapTable
                  bootstrap4
                  remote
                  wrapperClasses="table-responsive pad-20"
                  data={tableData}
                  columns={cols}
                  keyField="id"
                  containerStyle={{ width: "100%" }}
                  hover
                  noDataIndication="No data found"
                  rowEvents={rowEvents}
                  pagination={options}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default PriceDetails;
