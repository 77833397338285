import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Search from "../../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import ConfirmationModel from "../../../../shared/ConfirmationModel";

const Area = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Area",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openNested, setOpenNested] = useState(false);
  const [selectedRow, setselectedRow] = useState([]);
  const [checkboxstatus, setcheckboxstatus] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const deleteRow = (row, activeStatus) => {
    // For Future Delete operation

    // axios
    //   .delete(config.service + "/masters/area/delete/" + row.id, tokenKey)
    //   // .delete(
    //   //   "http://localhost:8000/api/masters/area/delete/29/" + row.id,
    //   //   tokenKey
    //   // )
    //   .then((resp) => {
    //     toast.success("Successfully Deleted", toastAttr);
    //     get(config.service + "/masters/area/list/", tokenKey).then((res) => {
    //       settableData(res.data);
    //     });
    //   })
    //   .catch((err) => {
    //     if (err.response.status === 401) window.location.href = "/";
    //     toast.error("Deletion Failed", toastAttr);
    //   });

    let url = config.service + "/masters/area/update/";
    // let url = "http://10.10.10.252:8081/api/masters/area/update/";
    let data = JSON.parse(JSON.stringify(row));
    data.area_id = row.id;
    data.active = activeStatus;
    let token = {
      Authorization: "Token " + sessionStorage.getItem("tokenKey"),
    };
    axios({
      url: url,
      method: "put",
      headers: token,
      data: data,
    })
      .then((res) => {
        toast.success(
          `${checkboxstatus ? "Activated" : "Deactivated"} Successsfully`,
          toastAttr
        );
        initialData();
      })
      .catch((err) => {
        toast.error("Area already exists", toastAttr);
      });
  };

  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/masters/areaForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/masters/areaForm",
        state: {},
      });
  };
  const renderOnSearch = (searchTerm) => {
    get(config.service + "/masters/area/list/?&search=" + searchTerm, tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Search Failed", toastAttr);
      });
  };

  const initialData = () => {
    get(config.service + "/masters/area/list/", tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  };

  useEffect(() => {
    initialData();
  }, []);

  const cols = [
    {
      dataField: "name",
      text: "Area Name",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "city_name",
      text: "City",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "state_name",
      text: "State",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "state_name",
      text: "State",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "active",
      text: "Active Status",
      headerStyle: () => {
        return { width: "250px" };
      },
      events: {
        onClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions ml-4">
            <React.Fragment>
              <div
                onClick={() => {
                  setOpenNested(true);
                }}
              >
                <input
                  type="checkbox"
                  checked={row.active}
                  onChange={() => {
                    setselectedRow(row);
                    setcheckboxstatus(!row.active);
                  }}
                />
              </div>
            </React.Fragment>
          </div>
        );
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerStyle: () => {
        return { width: "120px" };
      },
      events: {
        onClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions">
            <React.Fragment>
              <Link
                to={{
                  pathname: "/masters/areaForm",
                  state: { id: row.id, editMode: true },
                }}
              >
                <FiEdit2 title="Edit" />
              </Link>
            </React.Fragment>
          </div>
        );
      },
    },
  ];
  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/masters/areaForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    },
  };
  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    totalSize: tableData.length,
    hidePageListOnlyOnePage: true,
  });
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button variant="primary" onClick={(e) => renderForm()}>
                    Create
                  </Button>
                </Col>
                <Col md={4}>
                  <Search callbackFn={(e) => renderOnSearch(e)} />
                </Col>
              </Row>
              <Row className="vendorList">
                <BootstrapTable
                  bootstrap4
                  wrapperClasses="table-responsive pad-20"
                  data={tableData}
                  columns={cols}
                  keyField="id"
                  containerStyle={{ width: "100%" }}
                  hover
                  noDataIndication="No data found"
                  rowEvents={rowEvents}
                  pagination={options}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConfirmationModel
        operation={checkboxstatus ? "Add" : "Remove"}
        handleConfirmation={() => {
          setOpenNested(false);
          deleteRow(selectedRow, checkboxstatus);
        }}
        val={openNested}
        modelName={"Area"}
        onclose={() => {
          setOpenNested(false);
        }}
      />
    </Container>
  );
};
export default Area;
