import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Loader from "../../Helper/Loader";
import empty from "is-empty";
import OTPInput, { ResendOTP } from "otp-input-react";
import Select from "react-select";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import ResetPassword from "../../LogIn/components/ResetPasswordForm";
import { FaExclamationCircle } from "react-icons/fa";
import ErrorHandling from "../../Helper/ErrorHandling";

const UserForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [OTP, setOTP] = useState("");
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [role, setRole] = useState();
  const [vendor, setVendor] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [avilableVendors, setavilableVendors] = useState([]);
  const [openDialog, setopenDialog] = useState(false);
  const [userRoles, setuserRoles] = useState([
    { value: "admin", label: "Admin" },
    { value: "agent", label: "Agent" },
    { value: "vendor", label: "Vendor" },
    { value: "telecaller", label: "Tele Caller" },
  ]);
  const [handleErr, sethandleErr] = useState(false);
  const [errData, seterrData] = useState(null);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "User Details",
      id: "details",
      show: true,
    },
  ]);
  let breadcrumbData = [
    {
      title: "Users",
      href: "/user",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const toggleModal = () => {
    setShowModal(!showModal);
    setOTP("");
  };

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  const handleSelect = (keyName, setFn, e, isMulti) => {
    setFn(e);
    let vendor = form.vendor;
    if (keyName === "user_role") {
      vendor = [];
      setVendor(null);
    }
    setForm({
      ...form,
      vendor: vendor,
      [keyName]: isMulti ? e : (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/user");
  };

  const findErrors = () => {
    const { first_name, mobile, user_role } = form;
    const newErrors = {};
    if (empty(first_name)) newErrors.first_name = "This is a required field";
    if (empty(mobile)) newErrors.mobile = "This is a required field";
    if (empty(user_role)) newErrors.user_role = "This is a required field";

    return newErrors;
  };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? config.service + "/accounts/user/update/"
          : config.service + "/accounts/user/create/",
        data = JSON.parse(JSON.stringify(form));
      if (empty(form.username)) data.username = form.mobile;
      data.vendor_ids = [];
      if (form.user_role === "agent")
        data.vendor &&
          data.vendor.map((obj) => {
            data.vendor_ids.push(obj.value);
          });
      else if (form.user_role === "vendor") data.vendor_ids = [data.vendor];
      else data.vendor_ids = [];
      // delete data['vendor'];
      if (editMode) data.profile_id = data.id;
      console.log('payload',{
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          let data = res.data;
          if (editMode)
            setForm({
              first_name: data.user_id.first_name,
              last_name: data.user_id.last_name,
              mobile: data.mobile,
              username: data.user_id.username,
              user_role: data.user_role,
              id: data.id,
            });
          else
            setForm({
              ...form,
              password: data.user.password,
            });
          if (editMode) toast.success("Saved successfully", toastAttr);
          else
            toast.success(
              "User created successfully. Kindly note the password to login",
              { ...toastAttr, autoClose: 4000 }
            );
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else {
            seterrData(err.response && err.response.data);
            sethandleErr(true);
          }
          toast.error("Saving Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const closeErrorHandling = () => {
    sethandleErr(false);
    seterrData(null);
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const genOTP = () => {
    let data = {
      mobile: form.mobile,
      user_type: "customer",
    };
    post(config.service + "/sms/generateOtp/", data, { headers: tokenKey })
      .then((res) => {
        setShowModal(true);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("OTP generation failed", toastAttr);
      });
  };

  const verifyOTP = () => {
    let data = {
      mobile: form.mobile,
      user_type: "customer", //check
      otp: OTP,
    };
    post(config.service + "/sms/verifyOtp/", data, { headers: tokenKey })
      .then((res) => {
        toast.success("OTP verified successfully", toastAttr);
        toggleModal();
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("OTP verification failed", toastAttr);
      });
  };

  const renderButton = (buttonProps) => {
    return (
      <button className="btn btn-primary" {...buttonProps}>
        Resend OTP
      </button>
    );
  };
  const renderTime = (remainingTime) => {
    return <span>{remainingTime} seconds remaining</span>;
  };

  useEffect(() => {
    get(config.service + "/masters/base/vendor/", { headers: tokenKey })
      .then((resp) => {
        let list = [],
          avlVendors = [];
        resp.data.map((obj) => {
          list.push({ value: obj.id, label: obj.vendor_name });
        });
        setvendorList(list);
        get(config.service + "/masters/unassgined-all/", {
          headers: tokenKey,
        }).then((res) => {
          res.data.map((obj) => {
            avlVendors.push({ value: obj.id, label: obj.vendor_name });
          });
          if (propsState && propsState.editMode)
            get(config.service + "/accounts/user/" + propsState.id + "/", {
              headers: tokenKey,
            }).then((res) => {
              let vendorVal = [],
                data = res.data.profile;
              data.vendor_ids &&
                data.vendor_ids.map((vid) => {
                  let label = resp.data.filter((row) => row.id === vid)[0];
                  vendorVal.push({
                    value: vid,
                    label: (label && label.vendor_name) || "",
                  });
                });
              let roleval = userRoles.filter(
                (temp) => temp.value === data.user_role
              );
              setForm({
                first_name: data.user_id.first_name,
                last_name: data.user_id.last_name,
                mobile: data.mobile,
                username: data.user_id.username,
                user_role: data.user_role,
                id: data.id,
                vendor_ids: data.vendor_ids,
              });
              setRole(roleval);
              setVendor(vendorVal);
              if (data.user_role === "vendor") avlVendors.push(vendorVal[0]);
              setavilableVendors(avlVendors);
              setLoader(false);
            });
          else {
            setavilableVendors(avlVendors);
            setLoader(false);
          }
        });
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={6}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                  {/* {editMode ? <Button variant='primary' onClick={e=>genOTP()}>Generate OTP</Button> : ''} */}
                </Col>
                {editMode && form.user_role !== "customer" ? (
                  <Col md={6} style={{ textAlign: "right" }}>
                    <div className="resetPwd">
                      <a onClick={(e) => setopenDialog(true)}>Reset Password</a>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">
                                  Customer Details
                                </legend>
                                <Row>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>First Name</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="first_name"
                                          value={form.first_name}
                                          onChange={(e) =>
                                            handleInput(
                                              "first_name",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.first_name}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Last Name</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="last_name"
                                          value={form.last_name}
                                          onChange={(e) =>
                                            handleInput(
                                              "last_name",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Mobile</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="number"
                                          name="mobile"
                                          value={form.mobile}
                                          onChange={(e) =>
                                            handleInput(
                                              "mobile",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.mobile}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.mobile}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Username</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="username"
                                          value={form.username}
                                          onChange={(e) =>
                                            handleInput(
                                              "username",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.username}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.username}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <label>Role</label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.user_role
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={role}
                                          onChange={(e) =>
                                            handleSelect(
                                              "user_role",
                                              setRole,
                                              e
                                            )
                                          }
                                          options={userRoles}
                                          isClearable
                                        />
                                        {errors.user_role ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.user_role}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    {form.user_role &&
                                    form.user_role === "agent" ? (
                                      <Row>
                                        <Col md={4}>
                                          <label>Vendor</label>
                                        </Col>
                                        <Col md={7}>
                                          <Select
                                            className="selectBox"
                                            isMulti
                                            value={vendor}
                                            onChange={(e) =>
                                              handleSelect(
                                                "vendor",
                                                setVendor,
                                                e,
                                                true
                                              )
                                            }
                                            options={vendorList}
                                            isClearable
                                          />
                                        </Col>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                    {form.user_role &&
                                    form.user_role === "vendor" ? (
                                      <Row>
                                        <Col md={4}>
                                          <label>Vendor</label>
                                        </Col>
                                        <Col md={7}>
                                          <Select
                                            className="selectBox"
                                            value={vendor}
                                            onChange={(e) =>
                                              handleSelect(
                                                "vendor",
                                                setVendor,
                                                e
                                              )
                                            }
                                            options={avilableVendors}
                                            isClearable
                                          />
                                        </Col>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  {form.password ? (
                                    <Col md={6}>
                                      <Row>
                                        <Col md={4}>
                                          <Form.Label>
                                            Password{" "}
                                            <FaExclamationCircle
                                              title="Copy password to login"
                                              color="red"
                                              style={{ marginTop: "-2px" }}
                                              size="20"
                                            />
                                          </Form.Label>
                                        </Col>
                                        <Col md={7}>
                                          <Form.Control
                                            disabled
                                            type="text"
                                            name="password"
                                            value={form.password}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </fieldset>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Modal
                toggle={(e) => {
                  toggleModal();
                }}
                isOpen={showModal}
                className="otpModal"
              >
                <ModalHeader
                  toggle={(e) => {
                    toggleModal();
                  }}
                >
                  Verify OTP
                </ModalHeader>
                <ModalBody>
                  <div className="otpCont">
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      secure
                    />
                    <ResendOTP
                      className="otpResend"
                      renderButton={renderButton}
                      renderTime={renderTime}
                      onResendClick={() => console.log("Resend clicked")}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      verifyOTP();
                    }}
                  >
                    Verify
                  </Button>
                </ModalFooter>
              </Modal>
              {openDialog ? (
                <ResetPassword
                  username={form.username}
                  handleClose={(e) => {
                    setopenDialog(false);
                  }}
                />
              ) : (
                ""
              )}
              {handleErr ? (
                <ErrorHandling
                  title="user"
                  closeErrorHandling={closeErrorHandling}
                  error={errData}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default UserForm;
